html, body, #root, .app, .pushable, .pusher, .appBody {
  min-height: 100vh;
}

/* General */
.hidden {
  visibility: hidden;
}

.react-datepicker-popper {
  z-index: 3;
}

/* SideBar */
#donatosGiftCardMenu {
  width: 360px;
  border: 0px;
  background-color: rgba(255, 255, 255, 0.95);
  overflow: hidden!important;
  display: flex;
  flex-direction: column;

}
.overflow__header {
    background: #ac1a2a url(/img/bg-header.png) 0 0 no-repeat;
    background-size: auto 100%;
    height: 65px!important;
    min-height: 65px;
    padding-right: 60px;
    position: relative;
    top: 0px;
    left: 0px;
    flex-grow: 0;
}
.overflow__header .nav-toggle {
    position: absolute;
    right: 0px;
    top:20px
}
.overflow__header img {
    position: absolute;
    top: 50%;
    left: 20px;
    transform:translateY(-50%)
}
.logo img {
  max-width: 140px;
  height: 44px!important;
  max-height: 100%;
}
#donatosGiftCardMenu .sidebarContent {
  padding: 20px;
  overflow: scroll;
  bottom: 60px;
  flex-grow: 1;
  /* overflow-y: scroll; */
}
#donatosGiftCardMenu .sidebarContent a {
  color: rgb(27, 27, 27);
  padding: 0px;
  margin-bottom: 20px;
  text-align: left;
  font-family: Cabin, sans-serif;
  font-weight: normal;
}
#donatosGiftCardMenu a:hover {
  color: #e51b24;
  background-color: inherit;
}
#donatosGiftCardMenu .copyOfHeader {
  padding: 10px;
  border-bottom: 2px solid rgb(195, 195, 195);
}
#donatosGiftCardMenu .copyOfHeader a {
  height: 33px;
  font-size: 20px;
}
#donatosGiftCardMenu .actualSidebarMenu {
  padding: 30px 10px;
}
#donatosGiftCardMenu .actualSidebarMenu a {
  height: 29px;
  font-size: 18px;
}
.nav-toggle {
    -ms-flex-order: 1;
    order: 1;
    width: 24px;
    height: 18px;
    cursor: pointer;
    margin: 23px 18px 0;
    position:relative
}
.nav-toggle:after, .nav-toggle:before, .nav-toggle span {
    display: block;
    width: 24px;
    height: 4px;
    background: #67676e;
    border-radius: 2px;
    position:absolute
}
.nav-toggle span {
    top: 7px;
    text-indent: -9999px;
    overflow: hidden;
    transition:all .2s
}
.nav-toggle:after, .nav-toggle:before {
    content: ""
}
.nav-toggle:before {
    top:0
}
.nav-toggle:after {
    top:14px
}
.-close {
    margin:0
}
.-close:after, .-close:before, .-close span {
    display: block;
    width: 24px;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    background:#fff
}
.-close span {
    opacity:0
}
.-close:before {
    content: "";
    transform: rotate(45deg);
    top:8px
}
.-close:after {
    content: "";
    transform: rotate(-45deg);
    top:8px
}
.overflow__account {
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 15px 8px 15px 20px;
    background: #fff;
    box-shadow:0 -1px 0 0 rgba(0, 0, 0, .15)
}
.overflow__account h2 {
  /* font-family: Cabin sans-serif!important; */
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
}
.overflow__account .btn.-primary {
  background: #e51b24;
  color: #fff;
  border: 1px solid #c3c3c3;
  border-radius: 6px;
  padding: .6rem 1rem;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1em;
  font-weight: 700;
  font-family: Cabin,sans-serif;
}
.overflow__account .btn.-primary:hover {
  background-color: #ac1a2a!important;
  color: #fff!important;
}
.sidebarPushable, .pusher {
    transform: none !important;
}
.pusher {
    /* position: static !important; */
}
.pusher.dimmed::after {
    position: fixed !important;
}


/* Header Menu */
.appMenu {
  width: 100%;
  height: 65px !important;
  display: flex!important;
  justify-content: space-between!important;
}
.appMenu .item{
  padding: 0px!important;
}
.nav-toggle {
  order: 0;
  width: 24px;
  height: 18px;
  cursor: pointer;
  margin: 0 18px;
  position: relative;
}
.nav-toggle span {
  top: 7px;
  text-indent: -9999px;
  overflow: hidden;
  transition: all .2s;
  display: block;
  width: 24px;
  height: 4px;
  color: #919191;
  background: #67676e;
  border-radius: 2px;
  position: absolute;
}
.appMenu .nav-toggle::before {
  top: 0;
  content: "";
  display: block;
  width: 24px;
  height: 4px;
  background: #67676e;
  border-radius: 2px;
  position: absolute;
}
.appMenu .nav-toggle::after {
  top: 14px;
  content: "";
  display: block;
  width: 24px;
  height: 4px;
  background: #67676e;
  border-radius: 2px;
  position: absolute;
}
#desktopNavButtons {
  display: none;
  margin-left: 18px;
}
#desktopNavButtons a {
  display: inline-block;
  font-weight: 700;
  color: #1b1b1b;
  transition: color .2s;
  margin: 0 10px!important;
}
#desktopNavButtons a:hover {
  color: rgb(204, 27, 35)!important;
}
#buttonTrioDiv {
  width: 110px;
  display: flex;
  margin-right: 5px;
}
.openSearch {
  cursor: pointer;
  margin-right: 12px;
}
.appMenu a {
  border: none;
  color: #919191;
  text-decoration: none;
}
.icon-search {
  width: 24px;
  height: 24px;
}
.icon-user {
  width: 22px;
  height: 24px;
  margin-bottom: 2px;
}
.cartlink {
  margin-left: 12px;
}
.icon-cart {
  width: 24px;
  height: 23px;
  margin-bottom: 2px;
}
.icon-svg {
  fill: #5c5c63;
}

/* App */
.appBody {
  background-color: rgb(240, 239, 237);
  padding-bottom: 20px;
}
.pageSegment, .couponSegment {
  width: 90%;
  margin-left: auto!important;
  margin-right: auto!important;
  max-width: 1000px;
}
.pageSegment {
  padding: 30px 25px!important;
}
.couponSegment {
  background-color: #FFFFFF;
  border: 1px solid #AB1925!important;
  border-radius: 5px;
  padding: 10px 23px!important;
}
#couponHeader {
  color: #454545;
  font-family: Cabin, sans-serif!important;
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 19px;
  text-align: left;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 6px;
  margin-bottom: 5px;
}
#couponContent {
  color: #454545;
  font-family: OpenSans, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: -0.17px;
  line-height: 17px;
  text-align: left;
  margin-bottom: 25px;
}
.couponButton, .selectedCouponButton {
  border-radius: 6px;
  width: 95px;
  height: 36px;
}
.selectedCouponButton {
  background-color: #AC1A2B!important;
  color: #fff!important;
}
.cardForm h3 {
  border-bottom: 1px solid #aaa!important;
  font-family: Cabin, sans-serif!important;
  padding-bottom: 10px!important;
  margin: 20px 0px!important;
}
.cardForm label {
  font-size: 16px;
  font-family: Open Sans, Arial, sans-serif;
  font-weight: normal!important;
}
.cardForm .field .input {
  margin-bottom: 1rem!important;
}
.formAsterisk {
  margin-top: -10px!important;
}
#desktopHeaderImg {
  display: none;
}
#mobileHeaderImg {
  width: 100%;
  margin-top: 65px;
  margin-bottom: 20px;
}

/* Home */
.landingPage {
  display: flex;
  flex-direction: column;
}
.landPageCard {
  margin-left: auto!important;
  margin-right: auto!important;
  width: 250px!important;
  height: 180px;
  border-radius: 5px;
}
.landPageCard .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.landPageCard .content i {
  margin-right: 0px;
  margin-bottom: 20px;
}
.landPageCard .content svg {
  margin-right: 0px;
  margin-bottom: 20px;
  width: 66px;
  height: 56px;
  color: #000;
}
.landPageCard .content .label {
  font-weight: normal;
  padding: 10px 20px;
}

/* Card Design */
#cardMapping {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#cardMapping .cardDesign {
  margin: auto 8px 10px;
  border-radius: 4% / 6.3%!important;
  /* use aspect ratio 1.58 for card designs */
  width: 150px!important;
  height: 95px!important;
}
#cardMapping .selectedCard {
  width: 155px!important;
  height: 98px!important;
  margin: auto 6px 8px;
}
.addedRequired {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  font-weight: bold;
  margin-left: 17px;
}
.addedRequired::after {
  color: rgb(219, 40, 40);
  content: "*";
  display: inline-block;
  height: 19px;
  margin-bottom: 0px;
  margin-left: 3px;
  margin-top: -3px;
  vertical-align: top;

}
#chooseAmount #chooseAmountButtons {
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
}
#chooseAmount #chooseAmountButtons button {
  width: 100px;
  height: 70px;
  background-color: #f7f7f7;
  margin-bottom: 5px;
}
#chooseAmount .otherInputWrapper .ui.label {
  margin-top: 0px;
  padding-left: 6px;
  padding-right: 6px;
}
#chooseAmount .otherInputWrapper .ui.input {
  margin-bottom: 0px !important;
}
#chooseAmount .otherInputWrapper input {
  max-width: 120px;
}
#chooseADeliveryDate button.ui.red.active {
  background: #AC1A2B!important;
}
#chooseADeliveryDate button.ui.red.basic {
  box-shadow: 0 0 0 1px #AC1A2B inset!important;
  color: #AC1A2B !important;
}
#datePicker {
  width: 180px;
  margin-bottom: 8px;
}
.bottomSpacer {
  margin-bottom: 40px;
}
.missingContent {
  margin-top: 0px;
  background-color: #f5f5f5!important;
}
.missingContent h5 {
  margin-bottom: 0px!important;
  color: #a3a3a3!important;
}
.missingContentDiv {
  display: flex;
  flex-wrap: wrap;
}
.missingContentDiv h6 {
  margin: 0px 8px 0px 0px!important;
  font-size: 13px;
  font-weight: normal;
  color: #a3a3a3;
}
.missingContentDiv h6::before {
  content: "- "
}
.customCardAmount {
  width: 65px!important;
  height: 35px;
  margin: 0px!important;
}

/* Review/Payment Page */
#cardTypeDiv {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
#cardTypeDiv img {
  width: 40px;
  margin-right: 8px;
}
#review .subFormSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.cardDesign {
  width: 150px!important;
  height: 95px!important;
  border-radius: 4% / 6.3%!important;
}
.cardDesign .content, .cardDesign div {
  border-radius: 4% / 6.3%!important;
}
#review .cardDesign .content {
  display: flex;
  justify-content: center;
  align-items: center;
}
#review .finalRecipientDetails h5 {
  margin-top: 1px;
  margin-bottom: 1px;
  color: #aaa;
}
#review .subFormSection #reviewEdit {
  width: 60px;
  height: 32px;
  margin-top: 14px;
  margin-right: 0px;
  padding: 3px 5px;
}

#cardNumCvv {
  margin-bottom: 5px;
}
#cardNumCvv .field:nth-child(1) {
  width: 70%;
  min-width: 165px;
  max-width: 300px;
}
#cardNumCvv .field:nth-child(2) {
  width: 20%;
  min-width: 70px;
  max-width: 80px;
}
#monthYear .field:nth-child(1) {
  min-width: 160px!important;
}
#monthYear .field:nth-child(2) {
  min-width: 100px!important;
}
#billingFirstLast {
  margin-bottom: 5px;
}
#billingFirstLast .field {
  width: 50%!important;
}
#monthZip .field:nth-child(1) {
  min-width: 180px;
}
#monthZip .field:nth-child(2) {
  min-width: 100px;
}

.errorSegment h4, .errorSegment p {
  text-align: center;
}
.errorSegment h4:nth-child(1) {
  margin-bottom: 8px;
}
.errorSegment h4:nth-child(2) {
  margin-top: 8px;
}


/* Confirmation Page */
#orderConfirmation .confirmationDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
#orderConfirmation .confirmationDiv h2 {
  color: #AC1325;
  font-size: 24px;
  line-height: 29px;
  margin-top: 6px;
  margin-bottom: 0px;
}
#orderConfirmation .confirmationDiv h4:nth-child(3) {
  color: #000;
  font-size: 14px;
  font-weight: normal;
  line-height: 19px;
  margin-top: 19px;
  margin-bottom: 0px;
}
#orderConfirmation .confirmationDiv h4:nth-child(4) {
  color: #000;
  font-size: 14px;
  font-weight: normal;
  line-height: 19px;
  margin-top: 16px;
  margin-bottom: 0px;
  max-width: 400px;
}
#orderConfirmation .confirmationDiv h4:nth-child(4) font {
  font-weight: bold;
}
#orderConfirmation .confirmationDiv .detailSegment {
  background-color: #FFF;
  border: 0.5px solid #ccc;
  border-radius: 6px;
  /* text-align: left; */
  margin-top: 12px;
  margin-bottom: 0px;
  padding: auto 50px!important;
}
#orderConfirmation .confirmationDiv .detailSegment h4 {
  color: #000;
  font-size: 14px;
  line-height: 22px;
  margin-top: 0px;
  margin-bottom: 0px;
  /* margin-left: 20px; */
  font-weight: normal;
  text-align: left;
}
#orderConfirmation .confirmationDiv .detailSegment h4 font {
  font-weight: bold;
}
#orderConfirmation .confirmationDiv h4:nth-child(6) {
  margin-top: 16px;
  margin-bottom: 27px;
  color: #000000;
  font-size: 14px;
  line-height: 19.07px;
  width: 433px;

  font-weight: normal;
}

.donatosRedButton {
  background-color: #AC1A2B!important;
  color: #fff!important;
}


/* Balance Inquiry */
#balanceInquiry {
  margin-top: 10px;
}
#balanceInquiry .inquiryHeader {
  display: flex;
  margin: auto 0px;
  align-items: center;
  justify-content: center;
}
#balanceInquiry .inquiryHeader svg {
  color: #2e2e2e;
  width: 59px;
  height: 61px;
}
#balanceInquiry .inquiryHeader h4 {
  color: #6d7278;
  font-size: 14px;
  margin-top: 0px;
}
#balanceInquiry h3 {
  color: #AC1325;
  font-family: Cabin;
  font-size: 24px;
  line-height: 29px;
  margin-top: 4px;
  margin-bottom: 0px;
}
#balanceInquiry h5 {
  color: #6D7278;
  font-size: 14px;
  letter-spacing: -0.38px;
  line-height: 19.07px;
  text-align: center!important;
  margin: 5px auto 12px auto;
  max-width: 330px;
}
#balanceInquiry .inquiryForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
#balanceInquiry .inquiryForm .field {
  width: 185px;
}
#balanceInquiry .inquiryForm .field input{
  border: 1px solid #979797;
}
#balanceInquiry .inquiryForm .inquiryFormLabel {
  color: #545454;
  font-size: 14px;
  letter-spacing: -0.38px;
  line-height: 19px;
}
#balanceInquiry .inquiryForm button {
  background-color: #E50D17;
  color: #fff;
  border-radius: 5px;
  width: 152px;
  margin-right: 0px;
}
#balanceInquiry .cardBalanceDiv h3 {
  color: #000;
  font-size: 18px;
  letter-spacing: -0.49px;
  line-height: 24px;
  margin-bottom: 0px;
}
#balanceInquiry .cardBalanceDiv h4 {
  color: #6D7278;
  font-size: 18px;
  letter-spacing: -0.49px;
  line-height: 24px;
  margin-top: 0px;
}
#balanceInquiry .cardBalanceDiv h4 a {
  text-decoration: underline;
  color: #6D7278;
}
#balanceInquiry .invalidDiv {
  margin-top: 25px;
}
#balanceInquiry .invalidDiv h4:nth-child(1) {
  color: #AC1325;
  font-size: 18px;
  letter-spacing: -0.49px;
  line-height: 24px;
  margin-bottom: 0px;
}
#balanceInquiry .invalidDiv h4:nth-child(2) {
  color: #000;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.49px;
  line-height: 24px;
  margin-top: 0px;
}
#balanceInquiry .helpDiv {
  margin-top: 25px;
}
#balanceInquiry .helpDiv h4 {
  color: #000;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.49px;
  line-height: 24px;
  margin-bottom: 0px;
}
#balanceInquiry .helpDiv h3 {
  color: #AC1325;
  font-size: 18px;
  /* font-weight: normal; */
  letter-spacing: -0.49px;
  line-height: 21.87px;
  margin-top: 0px;
  margin-bottom: 20px;
}

/* Error Handling */
#errorHandling .errorHandlingContent h4 {
  margin-bottom: 8px;
}
#errorHandling .errorHandlingContent .segment {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}
#errorHandling .errorHandlingContent h3 {
  margin-top: 0px;
  margin-bottom: 8px;
  /* font-weight: normal; */
}
#errorHandling .errorHandlingContent h3 font {
  margin-bottom: 0px;
  color: #AC1A2B;
}

/* Footer */
#footer {
  background: url("https://www.donatos.com/img/wood-refresh.jpg");
  position: relative;
  z-index: 2;
  color: #1b1b1b;
  font-size: 1rem;
}
.navFooter {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  max-width: 1300px;
  color: #1b1b1b;
  font-size: 1rem;
}
.navFooter>:not(:last-child) {
  margin-bottom: 1rem;
}
.navFooter__apps {
  order: 2;
}
.navFooter__apps a {
  display: inline-block;
  height: 38px;
  margin: auto 5px;
}
.download-app-icon {
  height: 38px;
}
.navFooter__copy {
  order: 1;
  display: block;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: "Open Sans", Arial, sans-serif;
}
.navFooter__copy a {
  color: #e41f11!important;
}
.navFooter__copy a:hover {
  color: #1b1b1b!important;
}
.navFooter__social {
  order: 3;
  display: flex;
  align-items: center;
  width: 196px;
  margin: 0 auto;
}
.navFooter__social a {
  display: block;
}
.icon--block {
  display: inline-block;
  text-indent: -9999px;
  overflow: hidden;
  vertical-align: middle;
}
.navFooter__social a:not(:first-child) {
  margin-left: 20px;
}
.icon--yelp {
  background: url(/imgs/footer/yelp-black.svg) 0 0 no-repeat;
  width: 28px;
  height: 36px;
}
.icon--facebook {
  background: url(/imgs/footer/facebook-black.svg) 0 0 no-repeat;
  width: 36px;
  height: 36px;
}
.icon--instagram {
  background: url(/imgs/footer/instagram-black.svg) 0 0 no-repeat;
  width: 36px;
  height: 36px;
}
.icon--twitter {
  background: url(/imgs/footer/twitter-black.svg) 0 0 no-repeat;
  width: 36px;
  height: 32px;
}

@media screen and (min-width: 600px) {
  .landingPage {
    flex-direction: row;
    margin-top: 50px;
    justify-content: center;
  }
  .landPageCard {
    margin: 0px 8px!important;
  }
  #balanceInquiry h5 {
    max-width: 425px;
  }
  #balanceInquiry .inquiryForm {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  #balanceInquiry .inquiryForm .field {
    margin-right: 12px;
    margin-bottom: 0px;
  }
  #balanceInquiry .inquiryForm .field:nth-child(2) {
    width: 135px;
  }
  #balanceInquiry .inquiryForm button {
    margin-bottom: -22px;
  }
}
@media screen and (min-width: 800px) {
  .appBody {
    background-image: url("https://www.donatos.com/img/bg-pep.png");
    background-repeat: no-repeat;
    background-position-x: 0px;
    background-position-y: 0px;
  }
  #desktopHeaderImg {
    display: block;
    width: 100%;
    /* margin-top: 80px;
    margin-bottom: 20px; */
    margin: 65px auto 20px;
    max-width: 1000px;
  }
  #mobileHeaderImg {
    display: none;
  }
  .pageSegment {
    padding: 30px 40px 40px!important;
  }
  #cardMapping .cardDesign {
    /* use aspect ratio 1.58 for card designs */
    width: 240px!important;
    height: 151px!important;
  }
  #cardMapping .selectedCard {
    width: 247px!important;
    height: 156!important;
    margin: auto 4px 8px;
  }
  .cardDesign {
    /* use aspect ratio 1.58 for card designs */
    width: 240px!important;
    height: 151px!important;
  }
  #chooseADeliveryDate > div > div.ui.segment {
    display: flex;
    flex-direction: row;
  }
  #chooseADeliveryDate > div > div.ui.segment > div.react-datepicker-wrapper {
    margin-top: 23px;
    margin-right: 14px;
  }
}
@media screen and (min-width: 900px) {
  #detailPage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
  }
  #reviewPaymentPage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    max-width: 730px!important;
  }
  #paymentForm {
    max-width: 350px;
  }
  .buttonTrioDiv {
    /* display: none; */
  }
  .navContainer {
    width: 250px;
  }
  #mainPaymentForm {
    display: flex;
    flex-direction: row!important;
    justify-content: space-between;

  }
  #review, #paymentBillingSection {
    width: 50%;
  }
  #review {
    margin-right: 15px;
  }
  #paymentBillingSection {
    margin-left: 15px;
  }
}
@media screen and (min-width: 980px) {
  .navFooter {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .navFooter>:not(:last-child) {
    margin: 0;
  }
  .navFooter__apps {
    order: 1;
  }
  .navFooter>:not(:last-child) {
    margin: 0;
  }
  .navFooter__copy {
    order: 2;
  }
  .navFooter__social {
    margin: 0;
  }
}
@media screen and (max-width: 1019px) {
  #chooseAmount .otherInputWrapper {
    padding-top: 8px;
    margin-bottom: -30px;
    text-align: center;
  }
  #chooseAmount .otherInputWrapper label::before {
    content: "Other ";
  }
  #chooseAmount .otherInputWrapper input {
    margin: auto;
  }
}
@media screen and (min-width: 1020px) {
  #chooseAmount .optionsWrapper {
    display: flex;
    align-items: center;
  }
  #chooseAmount .optionsWrapper div:first-child {
    flex: 1 0;
  }
  #chooseAmount .otherInputWrapper {
    flex: 1 0;
    margin-bottom: -40px;
    padding-left: 6px;
  }
}

@media screen and (min-width: 1100px) {
  #desktopNavButtons{
    display: flex;
    justify-content: space-around;
    width: 760px;
  }
  #desktopNavButtons a {
    display: flex;
    align-items: center;
    /*color: #777;*/
    margin: auto;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Cabin', sans-serif!important;
  }
}
